<template>
    <page>
        <page-head :title="pageTitle">
            <div class="space-x-2 flex">
            </div>
        </page-head>

        <page-body>

            <data-grid
                :data-source="storeOrders"
                :row-prepared="rowPrepared"
                :row-alternation-enabled="false"
                :columns="columns"
                :action-column-width="120"
                :action-column-text="translate(translateKey + '.Label.Action')"
            >

                <div slot="actionSlot" slot-scope="list">

                    <app-button
                        property="warning"
                        @click="updateModal(list.row.form)"
                        :sm="true"
                        v-if="list.row.sold === 'no' && can(permission + '.update')"
                        :title="translate(translateKey + '.Label.CompleteSale')"
                        icon="icon-power-off"
                    />

                    <app-button
                        v-if="can(permission + '.delete')"
                        :sm="true"
                        @click="remove(list.row.id)"
                        :title="'sil'"
                        icon="icon-trash-2"
                        property="danger"
                    />

                </div>
            </data-grid>
        </page-body>
        <modal :id="'updateModal'" size="xs">
            <modal-head>
                <modal-title>{{ 'Satış məlumatlarını yenilə' }}</modal-title>
            </modal-head>
            <modal-body v-if="modelShow">
                <form @submit.prevent="update">
                    <grid>
                        <b>Oyun: {{form.game_name}}</b>
                        <b>Sifariş tarixi: {{form.order_date}}</b>
                        <b>Stock say: {{form.stock_number}}</b>

                        <form-group
                            :label="translate('Sifariş sayı')"
                            name="order_number"
                        >
                            <form-number v-model="form.order_number"/>
                        </form-group>

                        <form-group
                            :label="translate('Nağd')"
                            name="cash"
                        >
                            <form-number v-model="form.cash"/>
                        </form-group>

                        <form-group
                            :label="translate('Nağdsız')"
                            name="cashless"
                        >
                            <form-number v-model="form.cashless"/>
                        </form-group>

                        <form-group
                            :label="translate('Məhsul satıldı?')"
                            name="sold"
                        >
                            <form-tree-select :options="sold" displayExpr="name" v-model="form.sold"/>
                        </form-group>

                        <form-group
                            :label="translate('Qeyd')"
                            name="note"
                        >
                            <form-text-area style="min-height: 130px" v-model="form.note"/>
                        </form-group>


                        <app-button class="justify-center" property="success" type="submit">
                            {{ translate('Yenilə') }}
                        </app-button>
                    </grid>
                </form>
            </modal-body>
        </modal>
    </page>
</template>

<script>
/*
 * Import Components
 * */
import {mapActions, mapState} from 'vuex';

const modalId = 'createModal';
const translateKey = 'crm.Order';

export default {
    name: "StoreOrderIndex",
    data() {
        return {
            translateKey,
            accepted: null,
            pageTitle: null,
            modelShow: false,
            sold: [
                {id:'yes', name: 'Bəli'},
                {id:'no', name: 'Xeyir'},               
            ],
            columns: [
                {
                    caption: translateKey + '.Label.GameName',
                    dataField: 'store.name',
                    show: true
                },
                {
                    caption: translateKey + '.Label.Department',
                    dataField: 'store.department.name',
                    show: true
                },
                {
                    caption: translateKey + '.Label.Fullname',
                    dataField: 'user.fullname',
                    show: true
                },
                {
                    caption: translateKey + '.Label.Phone',
                    dataField: 'user.phone',
                    show: true,
                },
                {
                    caption: translateKey + '.Label.OrderDate',
                    dataField: 'order_date',
                    show: true,
                    allowFiltering: true
                },
                {
                    caption: translateKey + '.Label.Price',
                    dataField: 'store.price',
                    customizeText: data => data.valueText + ' AZN',
                    show: true,
                    allowFiltering: false
                },
                {
                    caption: translateKey + '.Label.OrderNumber',
                    dataField: 'order_number',
                    show: true,
                    allowFiltering: false
                },
            ],
            form: {}
            
        }
        
    },
    
    computed: {
        ...mapState('StoreOrdersStore', ['storeOrders']),

        
        permission() {
            return this.currentPage.permission;
        }
    },
    methods: {
        ...mapActions('StoreOrdersStore', ['getStoreOrders', 'getStoreOrder', 'deleteStoreOrder','updateStoreOrder']),
        /*
         * Form Create
         * */

         updateFormCreate(item) {
            this.form = {
                id: item.id,
                order_number: item.order_number || null,
                cash: item.cash || null,
                cashless: item.cashless || null,
                sold: item.sold || null,
                note: item.note || null,
            };
            this.modelShow = true;
        },

        formCreate(item = {}) {
            const form = {
                id: item.id || null,
                translates: {},
            }
            this.form = form;
            this.modelShow = true;
        },

        updateModal(item) {
            this.modal('updateModal')
            this.modelShow = false;
            this.resetError();
            this.updateFormCreate(item);
        },

        update(){
            this.updateStoreOrder(this.form)
            .then(r => {
                if (r) {
                    this.modal('updateModal');
                    this.getStoreOrders();
                }
            })
        },
        
        rowPrepared(e) {
            if (e.data?.sold === 'yes') {
                e.rowElement.style.backgroundColor = '#f1f6ff';
            }
            else if (e.data?.sold === 'no') {
                e.rowElement.style.backgroundColor = '#fff5ec';
            }
        },
        /*
         * Create Modal
         * */
        createModal(item = {}) {
            this.modal(this.modalId)
            this.modelShow = false;
            this.resetError();
            this.formCreate(item);
        },
        /*
         * Remove
         * */
        remove(id) {
            this.alert().then(r => this.getStoreOrder(id).then(r => this.getStoreOrders()))
        },
    },
    created() {
        this.getStoreOrders()
    },
}
</script>

<style scoped>

</style>
